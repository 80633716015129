import { MsgSend } from "cosmjs-types/cosmos/bank/v1beta1/tx"
import { DirectSecp256k1HdWallet } from "@cosmjs/proto-signing";
import { assertIsBroadcastTxSuccess, SigningStargateClient, StargateClient } from "@cosmjs/stargate";
import { useState } from "react";
import { ethers } from "ethers";
// const mnemonic = "surround miss nominee dream gap cross assault thank captain prosper drop duty group candy wealth weather scale put";
// const wallet = await DirectSecp256k1HdWallet.fromMnemonic(mnemonic);
// const [firstAccount] = await wallet.getAccounts();

// const rpcEndpoint = "https://rpc.my_tendermint_rpc_node";
// const client = await SigningStargateClient.connectWithSigner(rpcEndpoint, wallet);

// const recipient = "cosmos1xv9tklw7d82sezh9haa573wufgy59vmwe6xxe5";
// const amount = {
//   denom: "ucosm",
//   amount: "1234567",
// };
// const result = await client.sendTokens(firstAccount.address, recipient, [amount], "Have fun with your star coins");
// assertIsBroadcastTxSuccess(result);

// onSendClicked = async(e: MouseEvent<HTMLButtonElement>) => {
//     // Detect Keplr
//     const { keplr } = window
//     if (!keplr) {
//         alert("You need to install Keplr")
//         return
//     }
//     // Get the current state and amount of tokens that we want to transfer
//     const { denom, toSend } = this.state
//     const { faucetAddress, rpcUrl } = this.props
//     // Suggest the testnet chain to Keplr
//     await keplr.experimentalSuggestChain(this.getTestnetChainInfo())
//     // Create the signing client
//     const offlineSigner: OfflineSigner =
//         window.getOfflineSigner!("theta-testnet-001")
//     const signingClient = await SigningStargateClient.connectWithSigner(
//         rpcUrl,
//         offlineSigner,
//     )
//     // Get the address and balance of your user
//     const account: AccountData = (await offlineSigner.getAccounts())[0]
//     this.setState({
//         myAddress: account.address,
//         myBalance: (await signingClient.getBalance(account.address, denom))
//             .amount,
//     })
//     // Submit the transaction to send tokens to the faucet
//     const sendResult = await signingClient.sendTokens(
//         account.address,
//         faucetAddress,
//         [
//             {
//                 denom: denom,
//                 amount: toSend,
//             },
//         ],
//         {
//             amount: [{ denom: "uatom", amount: "500" }],
//             gas: "200000",
//         },
//     )
//     // Print the result to the console
//     console.log(sendResult)
//     // Update the balance in the user interface
//     this.setState({
//         myBalance: (await signingClient.getBalance(account.address, denom))
//             .amount,
//         faucetBalance: (
//             await signingClient.getBalance(faucetAddress, denom)
//         ).amount,
//     })
// }


const From_keplr = (props) => {

    const [txid,set_txid] = useState("");
    const [cross_status,set_cross_status] = useState("");
    const [amount,set_amount] = useState(0);
    const scan_url = "https://rinkeby.etherscan.io/tx/"+txid;

    function cross(txid){
        console.log(txid);
        console.log("cross");
        let url = "https://wlike-api-dev.anyway.network/likecoin/cross/to/evm";

        fetch(url,{
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                "txid": txid,
            }),
        })
            .then((res) => res.json())
            .then((data) => { 
                console.log(data);
                set_cross_status(data.status);
                set_txid(data.txid);
                // if (data.status === "success") {
                //     set_tx_status(data.status);
                //     set_success_txid_report({
                //         recipient: data.txid,
                //     });
                // } else {
                //     set_tx_status(data.status);
                //     set_fail_txid_report({
                //         reason: data.message,
                //     });
                // }
            })
            .catch((err) => {
                console.log(err);
            });
    }

    async function testing(){

        const rpcEndpoint = "https://node.testnet.like.co/rpc/";
        const wlike_vault_address = "like1ehy54c765lfyqrpurrtdsssks30gsjx9txzh2x";
        const denom = "nanoekil";
        const nanoekil_amount = amount *1000000000;
        const amount_to_send = nanoekil_amount.toString();
        console.log(amount_to_send, typeof amount_to_send);
        const chainId = "likecoin-public-testnet-5";
        const offlineSigner = window.keplr.getOfflineSigner(chainId);
        const accounts = await offlineSigner.getAccounts();
        const account = accounts[0];
        const signingClient = await SigningStargateClient.connectWithSigner(
            rpcEndpoint,
            offlineSigner,
        );
        const test_addr = ethers.utils.getAddress(props.address[0]);
        console.log(test_addr)
        console.log(props.address[0]);
        set_cross_status("crossing");
        await signingClient.sendTokens(
            account.address,
            wlike_vault_address,
            [
                {
                    denom: denom,
                    amount: amount_to_send,
                },
            ],
            {
                amount: [{ denom: "nanoekil", amount: "10000000000" }],
                gas: "200000",
            },
            test_addr,
        )
        .then((result) => {
            const txid = result.transactionHash;
            set_cross_status("transaction sent");
            console.log(txid);
            cross(txid)
            
        }).catch((err) => {
            console.log(err);
        }
        );

        
    }

return (
    <div>
    <h1>from_keplr</h1>
    <span>amount will send: {amount} wlike</span>
    <p></p>
    <span>send to: {props.cosmos_address}</span>
    <p></p>
    <span>send from: {props.address}</span>
    <p></p>
    <input
          className="inputbox"
          type="number"
          onInput={(e) => set_amount(e.target.value)}
          placeholder="How many you want to WRAP?"
        />
        <button 
        className="submit"
        onClick={() => testing()}
        >
            WRAP 
        </button>
        <h3>{cross_status}</h3>
        
        <a href={scan_url} target="_blank">{txid}</a>
    </div>
)};
export default From_keplr;

import React from "react";
// import { Ethers } from "ether";
import logo from "./logo_bridge_v3.png";
import { Nav, NavLink,  NavMenu, NavBtn, NavBtnLink, Wallet_connect_btn, NavBtn_cosmos } from "./NavbarEle";
const Navbar = (props) => {
  let button;
  if (props.address === "") {
    button = "WIP";
  } else {
      button =      
      <span className="address">
      {props.address.toString().substring(0, 6)}…
      {props.address.toString().substring(props.address.toString().length - 4)}
    </span>;
  }
  
  let cosmos_button;
  if (props.cosmos_address === "") {
    cosmos_button = "cosmos";
  } else {
      cosmos_button =      
      <span className="address">
      {props.cosmos_address.toString().substring(0, 6)}…
      {props.cosmos_address.toString().substring(props.cosmos_address.toString().length - 4)}
    </span>;
  }
  return (
    <>
      <Nav>
        <NavLink to="/">
          <img
            src={logo}
            className="app_logo"
            alt="logo"
            style={{ height: 50 }}
          />
        </NavLink>

        <NavMenu>
          <NavLink to="/claim">claim</NavLink>
          <NavLink to="/wrap">wrap</NavLink>
          <NavLink to="/status">check status</NavLink>
        </NavMenu>
        <Wallet_connect_btn>
        <NavBtn onClick={() => props.set_trigger(true)}>{button}</NavBtn>
        <NavBtn_cosmos onClick={() => props.set_trigger(true)}>{cosmos_button}</NavBtn_cosmos>
        </Wallet_connect_btn>
      </Nav>
    </>
  );
};

export default Navbar;

import abi from "./abi.json";
import React, { useEffect, useState } from "react";
import { ethers } from "ethers";

const From_metamask = (props) => {

    // const txid_check = async (tx_hash) => {
    //     console.log("tx_hash: " + tx_hash);
    //     const provider = new ethers.providers.Web3Provider(window.ethereum,"any");
    //     let txn_test = await provider.getTransaction(tx_hash);
    //     if (txn_test) {
    //         if (txn_test.blockNumber) {
    //             console.log("txn_test: ");
    //             console.log(txn_test);
    //             return txn_test
    //         }
    //     }
    
    // }

    const [txid,set_txid] = useState("");
    const [cross_status,set_cross_status] = useState("");
    const [amount,set_amount] = useState(0);
    const bigdipper_url = "https://testnet.bigdipper.live/likecoin/transactions/"+txid;

    function cross_to_like(txid){
        console.log("crossing");
        console.log(txid);
        set_cross_status("crossing");
        // let status = check_tx_status(txid);
        let url = "https://wlike-api-dev.anyway.network/likecoin/cross/to/likecoin";

        fetch(url,{
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                "txid": txid,
            }),
        })
            .then((res) => res.json())
            .then((data) => { 
                console.log(data);
                if (data.status === "success") {
                    set_cross_status(data.status);
                    set_txid(data.txid);
                } else {
                    set_cross_status(data.status);
                    set_txid(data.message);
                }
                // if (data.status === "success") {
                //     set_tx_status(data.status);
                //     set_success_txid_report({
                //         recipient: data.txid,
                //     });
                // } else {
                //     set_tx_status(data.status);
                //     set_fail_txid_report({
                //         reason: data.message,
                //     });
                // }
            })
            .catch((err) => {
                console.log(err);
            });
    }

    async function testing(){
        const provider = new ethers.providers.Web3Provider(window.ethereum,"any");
        const { chainId } = await provider.getNetwork()
        console.log(chainId) // 42
        
        if (chainId !== 4) {
            alert("Please switch to the rinkeby testnet");
            return;
        }
        
        const signer = provider.getSigner()
        
        let wlike_address = "0x5A9Ed15Bb303bca3F814710466eb53B31e5AA48c";

        let wlike_Contract = new ethers.Contract(wlike_address, abi, provider);

        // let wlike_contract = new window.ethereum.Contract(abi, wlike_address);
        // // var Contract = require('web3-eth-contract');

        const test = await wlike_Contract.burnable();
        const wlike_with_signer = wlike_Contract.connect(signer);
        const send_amount = ethers.utils.parseUnits(amount, 18);
        // let testing = wlike_contract.methods.burnable().call().then(console.log);
        console.log("burnable "+test);
        const test_address = props.cosmos_address;
        let tx = await wlike_with_signer.cross(send_amount, test_address)
        set_cross_status("transaction sent");
        await tx.wait()
        .then((tx_info) => {
            console.log(tx_info);
            let tx_hash = tx_info.transactionHash;
            console.log(tx_hash);
            cross_to_like(tx_hash);
            // txid_check(tx.hash).then((txn) => {
            //     console.log(txn);
                
        // });
    }).catch((err) => {
        console.log(err);
    });
}
        
return (
    <div>
        <div className="from_metamask">
        <h1>from_metamask</h1>
        <span>amount will send: {amount} wlike</span>
        <p></p>
        <span>send to: {props.cosmos_address}</span>
        <p></p>
        <span>send from: {props.address}</span> 
        <p></p>
        {/* <p>wlike_address: {wlike_address}</p>
        <p>wlike_contract: {wlike_Contract}</p> */}
        {/* <p>testing: {testing}</p> */}

        <input
          className="inputbox"
          type="number"
          onInput={(e) => set_amount(e.target.value)}
          placeholder="How many you want to WRAP?"
        />
        <button 
        className="submit"
        onClick={() => testing()}
        >
            WRAP 
        </button>
        <h3>{cross_status}</h3>

        <a href={bigdipper_url} target="_blank">{txid}</a>
        </div>

    </div>
);

};
export default From_metamask;

import { React, useState } from "react";
import "./styles.scss";
import From_keplr from "../../components/wrap/keplr";
import From_metamask from "../../components/wrap/metamask";
const Wrap = (props) => {
  // const [txid, set_txid] = useState("");
  // const [show_status, set_show_status] = useState(false);
  // const [submit_txid, set_submit_txid] = useState("");
  // console.log(show_status)
  // // const claim_token = () => {
  // //   let url = "http://127.0.0.1:8000/likecoin/cross/to/" + target_chain;
  // //   fetch(url)
  // //     .then((res) => res.json())
  // //     .then((data) => {
  // //       console.log(data);
  // //       if (data.status == "success") {
  // //         setstatus("success");
  // //       }
  // //     });
  // // };
  // const claim_token = () => {
  //   console.log("outside: "+txid);
  //   set_show_status(true);
  //   set_submit_txid(txid);
  // };

  const [amount,set_amount] = useState(0);
  const [target_chain,set_target_chain] = useState("");
  const [evm_border_color,set_evm_border_color] = useState("solid rgb(149, 231, 155)");
  const [likecoin_border_color,set_likecoin_border_color] = useState("");
  const [is_evm,set_is_evm] = useState(true);
  const bigdipper_url = "https://testnet.likecoin.bigdipper.live/accounts/" + props.cosmos_address;
  const etherscan_url = "https://rinkeby.etherscan.io/address/" + props.address;

  const network_handler= (network) => {
    if (network === "evm"){
      set_is_evm(true);
      set_evm_border_color("solid rgb(149, 231, 155)");
      set_likecoin_border_color("");
    }
    else{
      set_is_evm(false);
      set_evm_border_color("");
      set_likecoin_border_color("solid rgb(149, 231, 155)");
    }
  }

  return (
    <div className="container">
      <div className="Panel">
        <h1 className="title">WRAP</h1>
        
        <div className="network_select">
          <div className="select_box"
            style={{
              borderBottom: evm_border_color,
            }}
            onClick={() => network_handler("evm")}
          >
            From EVM
            </div>
            <div className="select_box"
            style={{
              borderBottom: likecoin_border_color,
            }}
            onClick={() => network_handler("likecoin")}
          >
            From LikeCoin Chain</div>
        </div>

        {is_evm ? (
          <From_metamask cosmos_address={props.cosmos_address} address={props.address}/>
        
        ) : (
          <From_keplr cosmos_address={props.cosmos_address} address={props.address}/> 
        )}

        
        {/* <button className="submit" onClick={claim_token}>
          submit
        </button> */}
        <div className="tutorial">
        <h3>HOW TO USE?</h3>
        <h3>如何使用？</h3>
        <p>
          please connect your metamask and keplr, and switch the network to:
          <br />
          請連接metamask和keplr，並切換網路為：
          <br />
          <br />
          <strong>METAMASK</strong>
          <br />
          rinkeby testnet
          <br />
          <br />
          <strong>KEPLR</strong>
          <br />
          LikeCoin public test chain
          <br />
          <br />
          choose which network that your fund come from, and type the amount of LIKE you want to wrap.
          <br />
          請選擇您想在哪條鏈發起跨鏈行為，並輸入你想跨鏈的 LIKE 數量。
          <br />
          <br />
          click WRAP, and wait for the transaction to be confirmed.
          <br />
          點擊 WRAP，等待交易確認。
          <br />
          <br />
          after the transaction is confirmed, you can see the transaction id above.
          <br />
          交易確認後，您可以看到上方的交易 id。
          <br />
          <br />
          if you get failure with no message, it might due to testnet RPC issue.
          <br />
          如果出現 failure 且沒有任何訊息，可能是因為 testnet RPC 問題。
          <br />
          <br />
          the transaction should be success, you can check it on <a href={bigdipper_url} target="_blank" >bigdipper</a> or <a href= {etherscan_url} target="_blank">etherscan</a>
        </p>
        </div>
      </div>
    </div>
  );
};

export default Wrap;

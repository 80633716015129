import { React, useState, useEffect } from "react";
import "./styles.scss";
import { Status_report } from "./tx_status";

const Claim = () => {
  const [txid, set_txid] = useState("");
  const [target, set_target] = useState("");
  const [submit_status, set_submit_status] = useState("");

  const claim_token = () => {
    if (txid === "" || target === "none") {
      alert("txid is empty");
      return;
    }
    set_submit_status(submit_status + 1);
  };

  const handleChange = (e) => {
    set_target(e.target.value);
  };

  return (
    <div
      className="container"
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "90vh",
      }}
    >
      <div className="Panel">
        <h1 className="title">Claim your token</h1>
        <div className="txid_input">
        <select onChange={handleChange}>
          <option value="none">crossing to...</option>
          <option value="evm">EVM</option>
          <option value="likecoin">likecoin</option>
        </select>
        <input
          className="inputbox"
          type="text"
          onInput={(e) => set_txid(e.target.value)}
          placeholder="paste your txid here."
        />
        </div>

        {submit_status && (
          <Status_report
            txid={txid}
            target={target}
            submit_status={submit_status}
          />
        )}
        <button className="submit" onClick={claim_token}>
          submit
        </button>
        <div className="tutorial">
          <h3> HOW TO USE?</h3>
          <h3>如何使用？</h3>
        <p>
          if you are using liker land, you need to transfer LIKE to this address:
          <br />
          如果您使用liker land，您需要將 LIKE 轉至此地址：
          <br />
          <br />
          <strong>like1ehy54c765lfyqrpurrtdsssks30gsjx9txzh2x</strong>
          <br />
          <br />
          please add an address that you want to receive into memo, it should be begin with "0x".
          <br />
          請在 memo 添加您想接收的地址，它應該以 "0x" 開頭。
          <br />
          <br />
          notice that your fund will lost if you do not add an address or the address you add is not valid.
          <br />
          請注意，如果您不添加地址或者您添加的地址不是有效的，您的資金將會遺失。
          <br />
          <br />
          after the transaction is done, copy the transaction hash, the length of the hash is 64 characters, 
          <br />
          在交易完成後，請複製交易哈希，哈希的長度為64個字符，
          <br />
          <br />
          and paste it into the input box above.
          <br />
          並貼在上面的輸入框中。
          <br />
          <br />
          the transaction will be submitted after you click the submit button.
          <br />
          在您點擊提交按鈕後，交易將會被提交。
          <br />
          <br />
          this process may take more then 5 minutes.
          <br />
          這個過程可能需要超過5分鐘。
        </p>
        </div>
      </div>
    </div>
  );
};

export default Claim;
